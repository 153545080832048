html {
  overflow-y: scroll;
}

ul {
  list-style-type: none;

}

li {
  padding: 0.5rem;
}

.left {
  float: left;
}

.margin {
  margin-block: 1.5rem;
}

.no-pointers {
  pointer-events: none;
}

.link {
  text-decoration: none;
  padding: 1vw 5vw;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
}

.link:before {
  content: "";
  background: linear-gradient(to right,
      transparent,
      #edecf1,
      #ded8ee);
  opacity: 0.5;
  border-radius: 1rem;
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
}

.link:hover:before {
  width: 100%;
  transition: width 600ms ease;
}